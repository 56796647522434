// Vue
import Vue from 'vue'
// Utils
import { get, isNil } from 'lodash'

export default {
  // Establecemos todas las raciones de una vez
  setRationsData: (state, value) => (state.rations = value),
  // Añadimos/actualizamos un tipo de ración
  setRationDataById: (state, { id = null, data }) => {
    if (isNil(get(state, 'rations', null))) {
      state.rations = []
    }
    const currentRationIndex = state.rations.findIndex((item) => {
      return item.id === id
    })

    if (currentRationIndex > -1) {
      // Actualizamos
      Vue.set(state.rations, currentRationIndex, {
        ...state.rations[currentRationIndex],
        ...data
      })
    } else {
      // Incluimos
      state.rations.push(data)
    }
    // Ordenamos
    state.rations = state.rations.sort((a, b) => {
      return a.order - b.order
    })
  },
  // Eliminamos un tipo de ración
  deleteRationDataById: (state, value) => {
    if (isNil(get(state, 'rations', null))) {
      state.rations = []
    }

    state.rations = state.rations.filter((item) => {
      return item.id !== value
    })
  },
  // Eliminamos (vaciamos) las raciones
  resetRationsData: (state) => (state.rations = null)
}
