import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js', {
    ready() {
      // TODO
    },
    registered() {
      // TODO
    },
    cached() {
      // TODO
    },
    updatefound() {
      // TODO
    },
    updated(reg) {
      console.info('Nuevo contenido. Actualizando...')
      reg.waiting.postMessage('skipWaiting')
    },
    offline() {
      // TODO
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

if ('serviceWorker' in navigator) {
  let refreshing = false
  // This is triggered when a new service worker take over
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) return
    refreshing = true

    window.location.reload()
  })
}
