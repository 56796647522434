// Constants
import { ADDONS } from '@/constants'
// utils
import { isNil, get } from 'lodash'

export default {
  // Idiomas adicionales
  additionalLanguages: (state, getters) => {
    const companyDataConfig = getters.companyDataConfig
    return get(companyDataConfig, 'additionalLanguages', [])
  },
  // ¿hay más de un idioma adicional?
  areThereAdditionalLanguages: (state, getters) => {
    return getters.additionalLanguages.length > 0
  },
  // Datos de la compañía
  companyData: (state) => {
    return isNil(state.data) ? null : { ...state.data }
  },
  // Configuración del addOn "company"
  companyDataConfig: (state, getters) => {
    const companyData = getters.companyData
    const addOnConfigs = get(companyData, 'addOnConfigs', [])
    const companyConfig = addOnConfigs.find((addon) => {
      return addon.id === ADDONS.company
    })

    return companyConfig && companyConfig.configFields ? companyConfig.configFields : {}
  },
  // Moneda
  currencyData: (state, getters) => {
    const companyDataConfig = getters.companyDataConfig
    return get(companyDataConfig, 'currency', null)
  }
}
