// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'VuetifyLoadingLayer',
  props: {
    /**
     * https://vuetifyjs.com/en/components/progress-circular/#progress-circular
     */
    loadingVOptions: {
      default() {
        return {
          size: '100',
          width: '4',
          indeterminate: true,
          color: 'white'
        }
      },
      type: Object
    },
    text: {
      type: String,
      default: null
    },
    translucid: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Return brand image
     *
     * @return {string} - brand image
     */
    getBrandImage() {
      return getPathImage('brand-loading.png', true)
    }
  }
}
