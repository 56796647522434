const dev = {
  apiKey: 'AIzaSyBr7cCyuSwXn5UNyA13UF5NhYDQjcybyQs',
  authDomain: 'bakarta-dev.firebaseapp.com',
  databaseURL: 'https://bakarta-dev.firebaseio.com',
  projectId: 'bakarta-dev',
  storageBucket: 'bakarta-dev.appspot.com',
  messagingSenderId: '924687783296',
  appId: '1:924687783296:web:86651b7b8f998042f55d65'
}

const prod = {
  apiKey: 'AIzaSyBr7cCyuSwXn5UNyA13UF5NhYDQjcybyQs',
  authDomain: 'bakarta-dev.firebaseapp.com',
  databaseURL: 'https://bakarta-dev.firebaseio.com',
  projectId: 'bakarta-dev',
  storageBucket: 'bakarta-dev.appspot.com',
  messagingSenderId: '924687783296',
  appId: '1:924687783296:web:86651b7b8f998042f55d65'
}

export default process.env.NODE_ENV === 'production' ? prod : dev
