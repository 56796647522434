// Firebase
import firebase from 'firebase/app'
// Vuex
import store from '@/store'
// Utils
import { isNil } from 'lodash'

export default () => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (isNil(firebaseUser)) {
        store.dispatch('authentication/logout')
      }
      // Devolvemos la resolución del usuario (logado o no)
      resolve(firebaseUser)
    })
  })
}
