// Constants
import { ROLES } from '@/constants'
// Services
import {
  getIdToken,
  updateProfile,
  updatePassword,
  updateEmail,
  signInWithEmailAndPassword,
  signOut
} from '@/firebase/authentication'
import { updateUserById, getUserById } from '@/services/user'
// Utils
import { createSessionCookie, removeSessionCookie } from '@/utils/session-cookie'

export default {
  /**
   * El usuario se loga en la aplicación
   */
  login: async ({ commit }, { email, password }) => {
    // Roles permitidos para logar
    const allowedRoles = Object.values(ROLES).map((role) => role.value)
    // Logamos en el sistema de autenticación
    const userLogged = await signInWithEmailAndPassword(email, password)
    // Token del usuario logado
    const idToken = await getIdToken()

    // Establecemos la cookie de sesión para las futuras peticiones a la API
    await createSessionCookie(idToken)

    // Obtenemos datos del usuario de la BD
    const user = await getUserById(userLogged.user.uid)

    // Solo permitimos logar a usuarios con los roles correctos
    if (allowedRoles.indexOf(user.role) === -1) {
      await signOut()
      throw new Error('El usuario/email indicado no pertenece a esta plataforma.')
    }

    // Datos del usuario en local
    commit('setUserData', {
      ...user,
      name: userLogged.user.displayName,
      email: userLogged.user.email
    })

    // Ocultamos loading si llegamos del registro
    commit(
      'app/setAppLoadingLayer',
      {
        visible: false
      },
      { root: true }
    )
  },
  /**
   * El usuario se "desloga" de la aplicación
   */
  logout: ({ commit }) => {
    // Eliminamos cookie de local
    removeSessionCookie()
    // Reset locale store
    commit('app/resetAppDialog', null, { root: true })
    commit('app/resetAppLoadingLayer', null, { root: true })
    commit('app/resetAppNotifications', null, { root: true })
    commit('company/resetCompanyData', null, { root: true })
    commit('meta/resetRationsData', null, { root: true })
    commit('config/resetAllergens', null, { root: true })
    commit('config/resetLanguages', null, { root: true })
    commit('resetUserData')
  },
  /**
   * Actualizamos usuario en el sistema de autenticaciones
   */
  updateUserAuth: async ({ commit }, { name, email, password, changePassword }) => {
    // Update password
    if (changePassword) {
      await updatePassword(password)
    }
    // Update user data
    await updateProfile({ displayName: name })
    // Update user email
    await updateEmail(email)
    // Set Store
    commit('setUserData', { name, email })
  },
  /**
   * Actualizamos usuario en base de datos (firestore)
   */
  updateUserData: async ({ commit }, data) => {
    // Update users
    await updateUserById(data)
    // Set Store
    commit('setUserData', data)
  }
}
