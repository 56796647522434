import state from './stickerOrders.state'
import mutations from './stickerOrders.mutations'
import actions from './stickerOrders.actions'
import getters from './stickerOrders.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
