// Vuex
import { mapState, mapMutations } from 'vuex'
// Components
import VuetifyAlert from '@/components/ui/VuetifyAlert'

export default {
  name: 'AppAlert',
  components: { VuetifyAlert },
  computed: {
    ...mapState('app', ['appAlert'])
  },
  watch: {
    // After 5 seconds alert will disapear
    appAlert(newValue) {
      if (
        newValue.visible &&
        newValue.dismissibleTimeOut > 0 &&
        newValue.type !== 'error' &&
        newValue.type !== 'warning'
      ) {
        setTimeout(() => {
          if (typeof this.resetAppAlert === 'function') {
            this.resetAppAlert()
          }
        }, newValue.dismissibleTimeOut)
      }
    }
  },
  methods: {
    ...mapMutations('app', ['resetAppAlert']),
    /**
     * Change the value (visible) to show or hide alert
     *
     * @param {Boolean} value - hide or show
     */
    handleDismissible(value) {
      if (!value) this.resetAppAlert()
    },
    /**
     * Click on the action button
     */
    handleClickActionButton() {
      if (typeof this.appAlert.actionButtonFn === 'function') {
        this.appAlert.actionButtonFn()
      }
      // reset alert
      this.resetAppAlert()
    }
  }
}
