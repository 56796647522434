// Constants
import { ROLES } from '@/constants'
// Layouts
const DefaultLayout = () => import('@/views/layout/DefaultLayout')
// Views (Private)
const ActivationsList = () => import('@/views/activations/ActivationsList')
const ActivationPlace = () => import('@/views/activations/ActivationPlace')
const PlacesList = () => import('@/views/places/PlacesList')
const PlacesDetail = () => import('@/views/places/PlacesDetail')
const PlacesManageMenus = () => import('@/views/places/PlacesManageMenus')
const CategoriesList = () => import('@/views/categories/CategoriesList')
const DishesList = () => import('@/views/dishes/DishesList')
const ManagedUsersList = () => import('@/views/users/ManagedUsersList')
const ConfigMain = () => import('@/views/configs/ConfigMain')
const ConfigMenu = () => import('@/views/configs/ConfigMenu')
const RationsList = () => import('@/views/rations/RationsList')
const StickerOrdersList = () => import('@/views/stickerOrders/StickerOrdersList')
// Views (Public)
const ActivationForm = () => import('@/views/activations/ActivationForm')
const Login = () => import('@/views/authentication/Login')
const RecoverPassword = () => import('@/views/authentication/RecoverPassword')
const Dashboard = () => import('@/views/app/Dashboard')
const UserFom = () => import('@/views/users/UserForm')

export default [
  // DefaultLayout
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      // Profile
      {
        path: '/profile',
        name: 'profile',
        component: UserFom,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      // Places
      {
        path: '/places',
        name: 'places-list',
        component: PlacesList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      {
        path: '/places/manage-menus/:id',
        name: 'places-manage-menus',
        component: PlacesManageMenus,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      {
        path: '/places/:id',
        name: 'places-detail',
        component: PlacesDetail,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      // Menus
      {
        path: '/menus',
        name: 'menus',
        component: CategoriesList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      {
        path: '/categories/:id',
        name: 'categories',
        component: CategoriesList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      {
        path: '/dishes/:id',
        name: 'dishes',
        component: DishesList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      // Activations
      {
        path: '/activations',
        name: 'activations-list',
        component: ActivationsList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      {
        path: '/activations/place/:id',
        name: 'activation-place',
        component: ActivationPlace,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      // Sticker orders
      {
        path: '/sticker-orders',
        name: 'sticker-orders-list',
        component: StickerOrdersList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      // Users
      {
        path: '/users',
        name: 'users-list',
        component: ManagedUsersList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      // Config
      {
        path: '/config',
        name: 'config-main',
        component: ConfigMain,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      {
        path: '/config/menu',
        name: 'config-menu',
        component: ConfigMenu,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      },
      {
        path: '/config/rations',
        name: 'config-rations',
        component: RationsList,
        meta: {
          roles: [ROLES.brand_admin.value]
        }
      }
    ]
  },
  // Auth
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/recover-password',
    name: 'recoverPassword',
    component: RecoverPassword,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/activation-form',
    name: 'activation-form',
    component: ActivationForm,
    meta: {
      isPublic: true
    }
  },
  { path: '*', redirect: '/' }
]
