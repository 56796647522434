// Constants
import { API_URL } from '@/constants'
// Lib
import axios from 'axios'
// Utils
import { get } from 'lodash'
// Session cookie
import { getSessionCookie } from '@/utils/session-cookie'

/**
 * Do a request to serve
 *
 * @param {Object} request - params to do request
 * @return {array | object| null}
 */
export async function doRequest(request) {
  try {
    // Default options request
    const defaultRequestOptions = {
      baseURL: API_URL,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getSessionCookie()}`,
        'Content-Type': 'application/json'
      },
      method: 'GET',
      timeout: 15000
    }
    const currentRequest = { ...defaultRequestOptions, ...request }

    // check Url
    if (!get(currentRequest, 'url', false)) {
      throw new Error('Debes indicar una URL')
    }

    const result = await axios(currentRequest)

    return result
  } catch (error) {
    // TODO - Por si se desea personalizar algún error
    // Datos recibidos del servidor
    const response = get(error, 'response.data', {})
    // Lanzamos el error "hacia arriba" para que otro "catch" lo capture
    throw new Error(response.message || 'Error al procesar la petición')
  }
}
