// Services
import {
  createRationInCompany,
  deleteRationById,
  getEveryRationsByCompanyId,
  updateRationById
} from '@/services/ration'
import { isNil } from 'lodash'

export default {
  /**
   * Creamos una nueva ración
   *
   * @param {object} data - Datos de la compañía
   * @param {string} companyId - UID compañía
   */
  createRationDataInCompany: async ({ commit }, { data, companyId, options = {} }) => {
    // Almacenamos en BD
    const { ration } = await createRationInCompany(data, companyId, options)

    if (!isNil(ration)) {
      // Establecemos en Store
      commit('setRationDataById', { id: null, data: ration })
    }

    return ration
  },
  /**
   * Obtenemos las raciones de la compañía de la BD
   * y la almacenamos en el Store
   *
   * @param {string} companyId - UID compañía
   */
  getEveryRationsDataByCompanyId: async ({ commit }, companyId) => {
    try {
      const rations = await getEveryRationsByCompanyId(companyId)
      commit('setRationsData', rations)
    } catch (error) {
      commit(
        'app/setAppAlert',
        { type: 'error', text: error.message, visible: true },
        { root: true }
      )
    }
  },
  /**
   * Eliminamos una ración
   *
   * @return {Boolean} - Devolvemos resultado de la acción
   */
  deleteRationDataById: async ({ commit }, { id, model, modelId }) => {
    // Eliminamos de BD
    const { ok } = await deleteRationById(id, model, modelId)

    if (ok) {
      // Eliminamos en Store
      commit('deleteRationDataById', id)
    }

    return ok
  },
  /**
   * Actualizamos datos de ración por UID de esta
   */
  updateRationDataById: async ({ commit }, { id, data, options = {} }) => {
    // Almacenamos en BD
    const rationId = await updateRationById({ id, ...data }, options)

    if (!isNil(rationId)) {
      // Establecemos en Store
      commit('setRationDataById', { id, data })
    }

    return rationId
  }
}
