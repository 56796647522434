// Services
import { updateCompanyById, getCompanyById, updateAddOnConfigs } from '@/services/company'
import { createUser, deleteUserById } from '@/services/user'
// Utils
import { get } from 'lodash'

export default {
  /**
   * Añade usuario a la compañía
   *
   * @param {object} data - datos para la petición
   */
  addUserToCompany: async ({ commit, rootState }, data) => {
    // Usuarios asociados a la compañía actualmente
    const usersCompany = get(rootState, 'company.data.users', {})
    // Creamos usuario
    const result = await createUser(data)
    // Actualizamos datos de la compañía en Store
    commit('setCompanyData', { users: { ...usersCompany, [result.id]: true } })
  },

  /**
   * Obtenemos datos de la compañía del servidor
   * y almacenamos en local
   */
  getCompanyData: async ({ commit }, id) => {
    // Update place in Store
    const data = await getCompanyById(id)
    commit('setCompanyData', data)
  },

  /**
   * Elimina usuario de la compañía
   *
   * @param {string} id - UID del usuario a eliminar
   */
  deleteUserFromCompany: async ({ commit, rootState }, id) => {
    // Usuarios asociados a la compañía actualmente
    const usersCompany = get(rootState, 'company.data.users', {})
    // Eliminamos usuario
    const { ok } = await deleteUserById(id)

    if (ok) {
      // Update company in Store
      commit('setCompanyData', {
        users: Object.keys(usersCompany)
          .filter((user) => id !== user)
          .reduce((sumUsers, user) => {
            sumUsers[[user]] = true
            return sumUsers
          }, {})
      })
    } else {
      throw new Error('Hubo un error al intentar eliminar el usuario')
    }
  },

  /**
   * Actualizamos los datos de la compañía en
   * el servidor y después es almacenado en local
   */
  updateCompanyData: async ({ commit }, data) => {
    // Update company in database
    await updateCompanyById(data)
    // Update company in Store
    commit('setCompanyData', data)
  },

  /**
   * Actualizamos configuraciones de la compañía
   *
   * @param {string} id - company UID
   * @param {object} addOns - options to save
   */
  updateCompanyConfigsData: async ({ commit }, { id, addOns = [] }) => {
    // Actualizamos datos en BD
    const results = await Promise.all(
      addOns.map(async (addOn) => {
        const result = await updateAddOnConfigs({ id, addOn })
        return result
      })
    )
    // Todas las llamadas funcionaron correctamente
    const everyIsOk = results.every((result) => result.ok)

    if (!everyIsOk) {
      throw new Error('Error al intentar almacenar los cambios')
    }

    // Actualizamos en Store
    commit('setCompanyConfigsData', addOns)
  }
}
