export const apiKey = 'AIzaSyAFAVNomMAJoDzBCa_0Jnc8yocLWzC1Lm0'
// Variables que nos llegan de "place"
// y cuáles deseamos almacenar
export const addressFormat = {
  subpremise: {
    id: 'subpremise',
    value: 'short_name'
  },
  street_number: {
    id: 'street_number',
    value: 'short_name'
  },
  route: {
    id: 'route',
    value: 'long_name'
  },
  locality: {
    id: 'locality',
    value: 'long_name'
  },
  administrative_area_level_1: {
    id: 'administrative_area_level_1',
    value: 'short_name'
  },
  administrative_area_level_2: {
    id: 'administrative_area_level_2',
    value: 'long_name'
  },
  country: {
    id: 'country',
    value: 'long_name'
  },
  country_short: {
    id: 'country',
    value: 'short_name'
  },
  postal_code: {
    id: 'postal_code',
    value: 'short_name'
  }
}
