import state from './meta.state'
import mutations from './meta.mutations'
import actions from './meta.actions'
import getters from './meta.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
