import { getConfigById } from '@/services/config'

export default {
  /**
   * Get from DB the last data from allergens
   */
  getEveryAllergens: async ({ commit }) => {
    try {
      const { data } = await getConfigById('allergens')
      commit('setAllergens', data)
    } catch (error) {
      commit(
        'app/setAppAlert',
        { type: 'error', text: error.message, visible: true },
        { root: true }
      )
    }
  },
  /**
   * Get from DB the last data from languages
   */
  getEveryLanguages: async ({ commit }) => {
    try {
      const { data } = await getConfigById('languages')
      commit('setLanguages', data)
    } catch (error) {
      commit(
        'app/setAppAlert',
        { type: 'error', text: error.message, visible: true },
        { root: true }
      )
    }
  }
}
