// Components
import FormButtons from '@/components/ui/FormButtons'
// Vuex
import { mapState } from 'vuex'
// Utils
import { cloneDeep, isNil } from 'lodash'

export default {
  name: 'VuetifyDialog',
  components: { FormButtons },
  props: {
    contentText: {
      type: String,
      default: null
    },
    contentComponent: {
      type: Object,
      default() {
        return {}
      }
    },
    contentComponentProps: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * https://vuetifyjs.com/en/components/dialogs/#dialogs
     */
    dialogVOptions: {
      default() {
        return {
          'max-width': '600px',
          scrollable: true,
          persistent: true
        }
      },
      type: Object
    },
    hideActionButtons: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    optionsActionsButtons: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: 'Título modal'
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Default option to dialog buttons
      defaultOptionsActionsButtons: {
        alignButtons: 'right', // left, center, right
        acceptButtonText: 'Aceptar',
        acceptButtonType: 'submit',
        acceptButtonClass: '',
        acceptButtonHide: false,
        acceptButtonLoading: false,
        acceptButtonDisabled: false,
        cancelButtonText: 'Cancelar',
        cancelButtonType: 'button',
        cancelButtonClass: '',
        cancelButtonHide: false,
        cancelButtonDisabled: false,
        defaultBehaviourButtons: true
      }
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Get the component to use in Dialog
     */
    getContentComponent() {
      return !isNil(this.contentComponent) ? cloneDeep(this.contentComponent) : null
    },
    /**
     * Get the component props to use in Dialog
     */
    getContentComponentProps() {
      return !isNil(this.contentComponentProps) ? cloneDeep(this.contentComponentProps) : null
    },
    /**
     * Get options (computed) to v-dialog
     */
    getDialogVOptions() {
      return this.extraSmallDevice
        ? { ...this.dialogVOptions, fullscreen: true }
        : this.dialogVOptions
    },
    /**
     * Get options (computed) to action buttons
     */
    getOptionsActionsButtons() {
      return {
        ...this.defaultOptionsActionsButtons,
        ...this.optionsActionsButtons
      }
    }
  },
  methods: {
    /**
     * Get the current state of dialog visibility
     *
     * @param {Boolean} value - hide or show
     */
    handleToggleDialog(value) {
      this.$emit('onToggleDialog', value)
    },
    /**
     * Handle on click "Accept button"
     *
     * @param {Object} event - event action
     */
    handleClickAcceptButton($event) {
      this.$emit('onClickAcceptButton', $event)
    },
    /**
     * Handle on click "Cancel button"
     *
     * @param {Object} event - event action
     */
    handleClickCancelButton($event) {
      this.$emit('onClickCancelButton', $event)
    },
    /**
     * Handle event from component loaded
     *
     * @param {Object} event - event action
     */
    handleEventComponent($event) {
      this.$emit('onEventComponent', $event)
    }
  }
}
