// Request
import { doRequest } from '@/utils/requestApi'
// utils
import { get, isNil } from 'lodash'

const fileDownload = require('js-file-download')

/**
 * Crea nuevo pedido de pegatinas
 *
 * @param {object} data - datos del pedido a crear
 * @return {object} - pedido creado
 */
export async function createStickerOrder(data, id = null) {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/sticker-order/create' : `v1/sticker-order/create/${id}`,
    data: {
      data
    }
  })

  return result.data
}

/**
 * Elimina un pedido de pegatinas pasado su UID
 *
 * @param {string} id - UID pedido en base de datos
 */
export async function deleteStickerOrderById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/sticker-order/${id}`
  })

  return result.data
}

/**
 * Obtenemos todos los pedidos de pegatinas, según
 * las opciones pasadas
 *
 * @param {object} options - opciones para obtener los pedidos
 * @return {array} - lista de pedidos
 */
export async function getEveryStickerOrders(options = {}) {
  const formatIsCSV = get(options, 'format', null) === 'csv'
  const result = await doRequest({
    method: 'post',
    url: 'v1/sticker-order',
    data: {
      ...options
    },
    ...(formatIsCSV
      ? {
          timeout: 20000,
          responseType: 'blob'
        }
      : {})
  })

  if (formatIsCSV) {
    return fileDownload(result.data, `sticker-orders_${Date.now().toString()}.csv`)
  } else {
    return result.data
  }
}

/**
 * Obtenemos todos los estados de los pedidos de pegatinas
 *
 * @return {array} - lista de estados
 */
export async function getEveryStickerOrdersStatus() {
  const result = await doRequest({
    method: 'get',
    url: 'v1/sticker-order/status'
  })

  return result.data
}

/**
 * Obtenemos todos los tipos de pegatinas disponibles
 *
 * @return {array} - lista de tipos de pegatinas
 */
export async function getEveryStickerOrdersTypes() {
  const result = await doRequest({
    method: 'get',
    url: 'v1/sticker-order/types'
  })

  return result.data
}

/**
 * Obtenemos pedidos de pegatinas por UID
 *
 * @param {string} id - UID "pedido de pegatinas" en base de datos
 * @return {object} - pedido de pegatinas
 */
export async function getStickerOrderById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/sticker-order/${id}`
  })

  return result.data
}

/**
 * Actualizamos pedido de pegatinas en base de datos
 *
 * @param {object} data - datos del pedido
 * @param {string} - UID del pedido actualizado
 */
export async function updateStickerOrderById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/sticker-order/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
