import { cloneDeep } from 'lodash'
import { get, set } from 'lodash'

export default {
  // Establecemos (actualizamos) los datos de la compañía
  setCompanyData: (state, value) => (state.data = { ...state.data, ...value }),
  // Establecemos (actualizamos) los datos de configuración de la compañía
  setCompanyConfigsData: (state, value) => {
    value.forEach((addOn) => {
      const indexConfig = state.data.addOnConfigs.findIndex((config) => {
        return config.id === addOn.id
      })

      if (indexConfig > -1) {
        set(
          state.data,
          `addOnConfigs[${indexConfig}].configFields`,
          cloneDeep({
            ...get(state.data, `addOnConfigs[${indexConfig}].configFields`, {}),
            ...addOn.configFields
          })
        )
      }
    })
  },
  // Reseteamos los datos de la compañía
  resetCompanyData: (state) => (state.data = null)
}
