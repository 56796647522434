// Vuex
import { mapState } from 'vuex'

export default {
  name: 'FormButtons',
  props: {
    alignButtons: {
      default: 'between', // left, center, right, between
      type: String
    },
    acceptButtonFn: {
      default: () => {},
      type: Function
    },
    acceptButtonText: {
      default: 'Enviar',
      type: String
    },
    acceptButtonType: {
      default: 'submit',
      type: String
    },
    acceptButtonClass: {
      default: 'secondary',
      type: String
    },
    acceptButtonHide: {
      default: false,
      type: Boolean
    },
    acceptButtonLoading: {
      default: false,
      type: Boolean
    },
    acceptButtonDisabled: {
      default: false,
      type: Boolean
    },
    cancelButtonFn: {
      default: () => {},
      type: Function
    },
    cancelButtonText: {
      default: 'Cancelar',
      type: String
    },
    cancelButtonType: {
      default: 'button',
      type: String
    },
    cancelButtonClass: {
      default: 'white',
      type: String
    },
    cancelButtonHide: {
      default: false,
      type: Boolean
    },
    cancelButtonDisabled: {
      default: false,
      type: Boolean
    },
    deleteButtonFn: {
      default: () => {},
      type: Function
    },
    deleteButtonText: {
      default: 'Eliminar',
      type: String
    },
    deleteButtonType: {
      default: 'button',
      type: String
    },
    deleteButtonClass: {
      default: '',
      type: String
    },
    deleteButtonHide: {
      default: true,
      type: Boolean
    },
    deleteButtonDisabled: {
      default: false,
      type: Boolean
    },
    defaultBehaviourButtons: {
      default: true,
      type: Boolean
    },
    /**
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions: {
      default() {
        return {}
      },
      type: Object
    },
    /**
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    cancelButtonVOptions: {
      default() {
        return {}
      },
      type: Object
    },
    /**
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    deleteButtonVOptions: {
      default() {
        return {}
      },
      type: Object
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Opciones mezcladas del botón aceptar
     */
    currentAcceptButtonVOptions() {
      return {
        elevation: 0,
        text: true,
        ...this.acceptButtonVOptions
      }
    },
    /**
     * Opciones mezcladas del botón cancelar
     */
    currentCancelButtonVOptions() {
      return {
        color: 'grey darken-2',
        elevation: 0,
        text: true,
        outlined: true,
        ...this.cancelButtonVOptions
      }
    },
    /**
     * Opciones mezcladas del botón eliminar
     */
    currentDeleteButtonVOptions() {
      return {
        color: 'red',
        elevation: 0,
        icon: true,
        ...this.deleteButtonVOptions
      }
    },
    /**
     * ¿Solo existe un botón visible?
     *
     * @return {Boolean}
     */
    areThereOnlyOneVisibleButton() {
      const buttons = [this.acceptButtonHide, this.cancelButtonHide, this.deleteButtonHide]

      return buttons.filter((button) => !button).length === 1
    },
    /**
     * Get current position (class CSS) for align buttons
     *
     * @return {string}
     */
    currentAlignButtons() {
      return this.extraSmallDevice ? 'mobile' : this.alignButtons
    },
    /**
     * Disabled all button when accept button is "loading"
     *
     * @return {Boolean}
     */
    disabledAllButtons() {
      return this.acceptButtonLoading && this.defaultBehaviourButtons
    }
  },
  methods: {
    /**
     * Return event on click accept button
     *
     * @return {Object} - event object
     */
    handleClickAcceptButton($event) {
      if (typeof this.acceptButtonFn === 'function') {
        this.acceptButtonFn()
      }
      this.$emit('onClickAcceptButton', $event)
    },
    /**
     * Return event on click accept button
     *
     * @return {Object} - event object
     */
    handleClickCancelButton($event) {
      if (typeof this.cancelButtonFn === 'function') {
        this.cancelButtonFn()
      }
      this.$emit('onClickCancelButton', $event)
    },
    /**
     * Return event on click delete button
     *
     * @return {Object} - event object
     */
    handleClickDeleteButton($event) {
      if (typeof this.deleteButtonFn === 'function') {
        this.deleteButtonFn()
      }
      this.$emit('onClickDeleteButton', $event)
    }
  }
}
