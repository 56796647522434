// Vue
import Vue from 'vue'
import defaultState from './app.defaultState'
// Utils
import { cloneDeep } from 'lodash'

const { appAlert, appDialog, appLoadingLayer, appNotifications } = defaultState

export default {
  // App
  setExtraSmallDevice: (state, value) => (state.extraSmallDevice = value),
  setMediumDevice: (state, value) => (state.mediumDevice = value),
  setLargeDevice: (state, value) => (state.largeDevice = value),
  setUrlRedirect: (state, value) => (state.urlRedirect = value),
  // Alert
  setAppAlert: (state, value) => (state.appAlert = { ...appAlert, ...value }),
  resetAppAlert: (state) => (state.appAlert = cloneDeep(appAlert)),
  // Dialog
  setAppDialog: (state, value) => (state.appDialog = { ...appDialog, ...value }),
  resetAppDialog: (state) => (state.appDialog = cloneDeep(appDialog)),
  // Loading
  setAppLoadingLayer: (state, value) => (state.appLoadingLayer = { ...appLoadingLayer, ...value }),
  resetAppLoadingLayer: (state) => (state.appLoadingLayer = cloneDeep(appLoadingLayer)),
  // Notifications
  addAppNotification: (state, { id, data }) => {
    const currentNotificationIndex = state.appNotifications.items.findIndex((item) => {
      return item.id === id
    })

    if (currentNotificationIndex > -1) {
      // Actualizamos notificación
      Vue.set(state.appNotifications.items, currentNotificationIndex, {
        id,
        ...data
      })
    } else {
      // Nueva notificación
      state.appNotifications.items.push({
        id,
        ...data
      })
    }
  },
  removeAppNotification: (state, value) => {
    if (Number.isInteger(value)) {
      state.appNotifications.items.splice(value, 1)
    } else {
      state.appNotifications.items = state.appNotifications.items.filter((item) => {
        return item.id !== value
      })
    }
  },
  resetAppNotifications: (state) => (state.appNotifications = cloneDeep(appNotifications)),
  // Filtros por página
  setSearchFormFiltersPerPage: (state, value) => {
    state.searchFormFiltersPerPage[value.page] = value.filters
  }
}
