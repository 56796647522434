// Servicios
import { getEveryStickerOrdersStatus, getEveryStickerOrdersTypes } from '@/services/stickerOrder'

export default {
  /**
   * Obtiene todos los tipos de estados
   * de los "pedidos de pegatinas" y los almacena en local
   */
  getEveryStickerOrdersStatus: async ({ commit }) => {
    try {
      const status = await getEveryStickerOrdersStatus()
      commit('setStickerOrdersStatus', status)
    } catch (error) {
      commit(
        'app/setAppAlert',
        { type: 'error', text: error.message, visible: true },
        { root: true }
      )
    }
  },
  /**
   * Obtiene todos los tipos de pegatinas posibles
   * de los "pedidos de pegatinas" y los almacena en local
   */
  getEveryStickerOrdersTypes: async ({ commit }) => {
    try {
      const types = await getEveryStickerOrdersTypes()
      commit('setStickerOrdersTypes', types)
    } catch (error) {
      commit(
        'app/setAppAlert',
        { type: 'error', text: error.message, visible: true },
        { root: true }
      )
    }
  }
}
