import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'
// Constants
const THEME_BACKGROUND = '#f9fafb'
const THEME_PRIMARY_COLOR = '#18283b'
const THEME_SECONDARY_COLOR = '#1bd698'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        background_color: THEME_BACKGROUND,
        primary: THEME_PRIMARY_COLOR,
        secondary: THEME_SECONDARY_COLOR
      },
      dark: {
        background_color: THEME_BACKGROUND,
        primary: THEME_PRIMARY_COLOR,
        secondary: THEME_SECONDARY_COLOR
      }
    }
  }
})
