// Vue
import Vue from 'vue'
// Utils
import { isNil } from 'lodash'
// Firebase
import '@/firebase/init'
import authStateChanged from '@/firebase/authentication/auth-state-changed'
// App
import App from './app.vue'
import router from './router'
import store from './store'
// SW and Others
import '@/utils/register-service-worker'
import 'pwacompat'
// Framework CSS
import vuetify from './plugins/vuetify'
// Session Cookie
import { createSessionCookie } from '@/utils/session-cookie'
// TimePicker (TODO - Cargar en su sitio)
import 'vue2-timepicker/dist/VueTimepicker.css'

/**
 * Función asíncrona para cargar la APP, debido a que Firebase "tarda"
 * en resolver si el usuario está o no logado contra él y debido a que las peticiones
 * a la API comenzaban antes de ello, dando error de autenticación, paramos la carga
 * de la APP hasta conocer el estado del usuario en Firebase
 */
async function loadAppAsync() {
  // Estado del usuario en Firebase
  const firebaseUser = await authStateChanged()

  // Creamos la Cookie del usuario que se encuenta logado
  if (!isNil(firebaseUser)) {
    await createSessionCookie()
  }

  Vue.config.productionTip = false

  // Iniciamos Vue
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
  }).$mount('#app')
}

// Cargamos la aplicación
loadAppAsync()
