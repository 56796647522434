// Library
import googleMapsApiLoader from 'google-maps-api-loader'
// Config
import { apiKey } from './config'
// Utils
import { isNil } from 'lodash'
import { timeout } from '@/utils'

let googleMapAPI = null

/**
 * Carga las librerías de Google Maps
 *
 * @param {Object} params - parámetros opcionales de carga
 * @param {Number} seconds - Tiempo de espera antes de la carga
 * @return {Object}
 */
export async function loadGoogleMap(params = {}, seconds = 500) {
  await timeout(seconds)
  if (isNil(googleMapAPI)) {
    googleMapAPI = await googleMapsApiLoader({
      apiKey,
      ...params
    })
  }
  return googleMapAPI
}
