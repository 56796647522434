// Constans
import { ROLES } from '@/constants'
// Mixin
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapState, mapMutations, mapGetters } from 'vuex'
// Utils
import { isNil, get } from 'lodash'

// Ruta de "login"
const loginPath = 'login'
// Rutas desde donde se puede acceder
const allowedLoginPaths = [loginPath]

export default {
  mixins: [uiMixin],
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapState('app', ['urlRedirect'])
  },
  watch: {
    userData: {
      handler(newValue, oldValue) {
        // Current URL
        const routeName = this.$route.name

        if (newValue === undefined) return

        // El usuario ha sido borrado (vaciado store), por lo tanto
        // enviamos al usuario al "login"
        if (isNil(newValue.id) && !isNil(oldValue) && !isNil(oldValue.id)) {
          // Devolvemos al usuario al login
          this.routerReplaceTo({
            name: loginPath
          })
        }

        // El usuario ha accedido por alguna de las rutas
        // disponibles para tal fin
        if (
          !isNil(newValue.id) &&
          (isNil(oldValue) || isNil(oldValue.id)) &&
          allowedLoginPaths.indexOf(routeName) > -1
        ) {
          // Url del usuario (rol)
          let urlHome = get(ROLES, `${newValue.role}.urlHome`, '/')

          // Vamos a una url almacenada
          if (!isNil(this.urlRedirect)) {
            urlHome = this.urlRedirect
            // reseteamos redirección
            this.setUrlRedirect(null)
          }

          // Redirigimos tras hacer login
          this.routerReplaceTo(urlHome)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('app', ['setUrlRedirect'])
  }
}
