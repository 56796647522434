// Firebase
import firebase from 'firebase/app'
// Utils
import { isNil } from 'lodash'
// Contants
import { PANEL_URL } from '@/constants'

/**
 * Obtenemos el usuario logado
 *
 * @return {object} - usuario logado
 */
export function getCurrentUser() {
  const currentUser = firebase.auth().currentUser
  return currentUser
}

/**
 * Obtenemos el token del usuario que actualmente está logado
 *
 * @return {string}
 */
export async function getIdToken() {
  const currentUser = getCurrentUser()

  if (isNil(currentUser)) {
    return null
  }

  const token = await currentUser.getIdToken()
  return token
}

/**
 * Login user in firebase with email and password
 *
 * @param {string} email - email's user
 * @param {string} password - password's user
 * @return {promise}
 */
export async function signInWithEmailAndPassword(email, password) {
  const user = await firebase.auth().signInWithEmailAndPassword(email, password)
  return user
}

/**
 * Change email to current user
 *
 * @param {string} email - user data
 * @return {promise}
 */
export async function updateEmail(email) {
  const currentUser = getCurrentUser()

  if (isNil(currentUser)) {
    throw new Error('No se pudo actualizar el email del usuario.')
  }

  await currentUser.updateEmail(email)
}

/**
 * Change password to current user
 *
 * @param {Object} password - password's user
 * @return {promise}
 */
export async function updatePassword(password) {
  const currentUser = getCurrentUser()

  if (isNil(currentUser)) {
    throw new Error('No se pudo actualizar la contraseña del usuario.')
  }

  await currentUser.updatePassword(password)
}

/**
 * Update data current user
 *
 * @param {Object} data - user data
 * @return {promise}
 */
export async function updateProfile(data) {
  const currentUser = getCurrentUser()

  if (isNil(currentUser)) {
    throw new Error('No se pudo actualizar los datos del usuario.')
  }

  await firebase.auth().currentUser.updateProfile(data)
}

/**
 * SignOut user from firebase
 */
export async function signOut() {
  await firebase.auth().signOut()
}

/**
 * Envia un mensaje al email para recuperar la contraseña
 *
 * @param {string} email - user email
 * @return {promise}
 */
export async function recoverPassword(email) {
  await firebase.auth().sendPasswordResetEmail(email, {
    url: PANEL_URL,
    handleCodeInApp: false
  })
}
