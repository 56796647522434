// Components
import VuetifyDialog from '@/components/ui/VuetifyDialog'
// Vuex
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'AppDialog',
  components: { VuetifyDialog },
  props: {
    /**
     * https://vuetifyjs.com/en/components/dialogs/#dialogs
     */
    dialogVOptions: {
      default() {
        return {
          'max-width': '600px',
          scrollable: true,
          persistent: true
        }
      },
      type: Object
    }
  },
  computed: {
    ...mapState('app', ['appDialog']),
    /**
     * Get dialog options
     */
    getDialogOptions() {
      return { ...this.dialogVOptions, ...this.appDialog }
    }
  },
  watch: {
    $route() {
      this.resetAppDialog()
    }
  },
  methods: {
    ...mapMutations('app', ['resetAppDialog']),
    /**
     * Get the current state of dialog visibility
     *
     * @param {Boolean} value - hide or show
     */
    handleToggleDialog(value) {
      if (!value) this.resetAppDialog()
    },
    /**
     * Handle on click "Accept button"
     */
    handleClickAcceptButton() {
      if (typeof this.appDialog.acceptButtonFn === 'function') {
        this.appDialog.acceptButtonFn()
      }
      this.resetAppDialog()
    },
    /**
     * Handle on click "Cancel button"
     */
    handleClickCancelButton() {
      if (typeof this.appDialog.cancelButtonFn === 'function') {
        this.appDialog.cancelButtonFn()
      }
      this.resetAppDialog()
    }
  }
}
