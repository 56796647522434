import state from './activations.state'
import mutations from './activations.mutations'
import actions from './activations.actions'
import getters from './activations.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
