import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// contants
import { VUEX_PERSISTED_KEY } from '@/constants'
// Vuex modules
import activations from './activations'
import app from './app'
import authentication from './authentication'
import company from './company'
import config from './config'
import meta from './meta'
import stickerOrders from './stickerOrders'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key: VUEX_PERSISTED_KEY,
      paths: ['app.urlRedirect', 'authentication.user', 'company', 'meta']
    })
  ],
  modules: {
    activations,
    app,
    authentication,
    company,
    config,
    meta,
    stickerOrders
  }
})
