// THEMES
export const THEME_BACKGROUND = '#f9fafb'
export const THEME_PRIMARY_COLOR = '#18283b'
export const THEME_SECONDARY_COLOR = '#1bd698'
// VUEX (persistance)
export const VUEX_PERSISTED_KEY = 'bakarta_panel'
// IDIOMA POR DEFECTO
export const DEFAULT_LANGUAGE = 'es'
// API SERVER
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://us-central1-bakarta-dev.cloudfunctions.net/onRequest-api'
    : 'http://localhost:7001/bakarta-dev/us-central1/onRequest-api'
// export const API_URL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://europe-west1-bakarta-kustovi.cloudfunctions.net/onRequest-api'
//     : 'http://localhost:7001/bakarta-dev/us-central1/onRequest-api'
// APP's URL
export const HORECA_URL =
  process.env.NODE_ENV !== 'production'
    ? 'https://app.bakarta.com/'
    : 'https://horecadev.bakarta.com/'
export const MENU_URL =
  process.env.NODE_ENV !== 'production'
    ? 'https://mybakarta.com/'
    : 'http://bakarta-menu-dev.web.app/'
export const PANEL_URL =
  process.env.NODE_ENV !== 'production'
    ? 'https://panel.bakarta.com/'
    : 'https://paneldev.bakarta.com/'
// COOKIE
export const BAKARTA_COOKIE = 'bakarta_session_id'
export const BAKARTA_COOKIE_OPTIONS = {
  domain: process.env.NODE_ENV === 'production' ? '.bakarta.com' : null,
  expires: 7,
  ...(process.env.NODE_ENV === 'production' ? { secure: true } : {})
}
// LIMITE CONSULTA
export const QUERY_LIMITS = [null, 1, 50, 100, 500, 1000, 2500, 'all']
// COMPANY
export const COMPANY_TYPES = {
  brand: 'brand',
  horeca: 'horeca',
  partner: 'partner'
}
// ADDONS
export const ADDONS = {
  company: 'company',
  contact: 'contact',
  place: 'place'
}
export const PACKAGES = {
  free: {
    label: 'FREE',
    value: 'free'
  },
  pro: {
    label: 'PRO',
    value: 'pro'
  }
}
// MENUS
export const MENUS_TYPES = {
  place: {
    label: 'Establecimiento',
    value: 'place'
  },
  takeAway: {
    label: 'Take Away',
    value: 'takeAway'
  }
}
// ROLES
export const ROLES = {
  brand_admin: {
    label: 'Admin panel',
    urlHome: '/dashboard',
    value: 'brand_admin'
  },
  horeca_admin: {
    label: 'Admin horeca',
    urlHome: '/dashboard',
    value: 'horeca_admin'
  }
}
export const DEFAULT_PASSWORD = 'cartadigitalbybakarta'
// ACTIVACIONES
export const ACTIVATION_TYPES = {
  assistant: {
    label: 'Activación Asistida',
    value: 'assistant'
  },
  noActivation: {
    label: 'Sin activación',
    value: 'no'
  },
  noEmail: {
    label: 'Email en uso',
    value: 'noEmail'
  },
  noPhoto: {
    label: 'Sin fotos',
    value: 'noPhoto'
  },
  self: {
    label: 'Autoactivación',
    value: 'self'
  },
  rejected: {
    label: 'En revisión',
    value: 'rejected'
  },
  support: {
    label: 'Soporte',
    value: 'support'
  }
}
export const ACTIVATION_STATUS = {
  done: {
    label: 'Hecho',
    value: 'done'
  },
  inProcess: {
    label: 'En proceso',
    value: 'inProcess'
  },
  pending: {
    label: 'Pendiente',
    value: 'pending'
  }
}
