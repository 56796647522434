export default {
  name: 'VuetifyAlert',
  props: {
    /**
     * https://vuetifyjs.com/en/components/alerts/#alerts
     */
    alertVOptions: {
      type: Object,
      default() {
        return {
          'close-icon': 'mdi-close-thick',
          dismissible: true,
          elevation: 5,
          icon: false
        }
      }
    },
    actionButtonFn: {
      type: Function,
      default() {
        return false
      }
    },
    actionButtonText: {
      type: String,
      default: 'Accept'
    },
    color: {
      type: String,
      default: ''
    },
    dismissibleTimeOut: {
      type: Number,
      default: 5000
    },
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'info'
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Get alert Vuetify options
     *
     * @return {Object} - alert options
     */
    getAlertVOptions() {
      const customAlertVOptions = this.color ? { color: this.color } : { type: this.type }

      return {
        ...this.alertVOptions,
        ...customAlertVOptions
      }
    }
  },
  methods: {
    /**
     * Handle click on "dismissible" button. Event sent to parent
     *
     * @param {Boolean} value - hide or show
     */
    handleDismissible(value) {
      this.$emit('onDismissible', value)
    },
    /**
     * Handle click on "action" button. Event sent to parent
     *
     * @param {Object} $event - triggered event
     */
    handleClickActionButton($event) {
      this.$emit('onClickActionButton', $event)
    }
  }
}
