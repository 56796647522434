import state from './config.state'
import mutations from './config.mutations'
import actions from './config.actions'
import getters from './config.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
