// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Get every user by UID
 *
 * @param {Array} ids - UID user in database
 */
export async function getEveryUsersByIds(ids) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/user/every',
    data: {
      ids
    }
  })

  return result.data
}

/**
 * Get user by UID
 *
 * @param {string} id - UID user in database
 */
export async function getUserById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/user/${id}`
  })

  return result.data
}

/**
 * Update user in database
 *
 * @param {Object} data - user data
 */
export async function updateUserById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/user/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}

/**
 * Creamos un usuario nuevo en base de datos y autenticación
 *
 * @param {object} data - datos para la petición
 */
export async function createUser(data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/user',
    data: {
      data
    }
  })

  return result.data
}

/**
 * Eliminamos un usuario
 *
 * @param {object} id - UID del usuario
 */
export async function deleteUserById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/user/${id}`
  })

  return result.data
}
