// Components
import AppAlert from '@/components/elements/store/AppAlert'
import AppDialog from '@/components/elements/store/AppDialog'
import AppLoadingLayer from '@/components/elements/store/AppLoadingLayer'
import PrintVersion from '@/components/ui/PrintVersion'
// Mixins
import authMixin from '@/mixins/authMixin'
// Vuex
import { mapActions, mapMutations } from 'vuex'
// Google
import { loadGoogleMap } from '@/services/googleMap'
// Utils
import { refreshSessionCookie } from '@/utils/session-cookie'

// Version
const version = require('../package.json').version

export default {
  name: 'App',
  components: { AppAlert, AppDialog, AppLoadingLayer, PrintVersion },
  mixins: [authMixin],
  data: function () {
    return {
      intervalSession: null // Para el loop de las sesiones
    }
  },
  computed: {
    /**
     * Current versión App
     *
     * @return {String}
     */
    appVersion() {
      return version
    }
  },
  async created() {
    // Cargamos librerías de GoogleMaps
    loadGoogleMap(
      {
        libraries: ['places', 'visualization']
      },
      0
    )
    // Iniciamos "interval" para mantener la sesión viva (5 minutos)
    this.intervalSession = setInterval(async () => await this.refreshCookie(), 300000)
  },
  beforeDestroy() {
    // Matamos "interval" de sesiones
    clearInterval(this.intervalSession)
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  watch: {
    // Se cambia la resolución de la pantalla
    '$vuetify.breakpoint.name': {
      handler(value) {
        // Large device
        this.setLargeDevice(value === 'lg' || value === 'xl')
        // Medium device
        this.setMediumDevice(value === 'md' || value === 'sm')
        // Small device
        this.setExtraSmallDevice(value === 'xs')
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('stickerOrders', ['getEveryStickerOrdersStatus', 'getEveryStickerOrdersTypes']),
    ...mapMutations('app', ['setExtraSmallDevice', 'setMediumDevice', 'setLargeDevice']),
    /**
     * Inicializamos/obtenemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      // Todos los estados disponibles de "pedidos de pegatinas"
      await this.getEveryStickerOrdersStatus()
      // Todos los tipos disponibles de "pedidos de pegatinas"
      await this.getEveryStickerOrdersTypes()
    },
    /**
     * Refrescamos la cookie se sesión
     */
    async refreshCookie() {
      await refreshSessionCookie()
    }
  }
}
