// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Get config by UID
 *
 * @param {string} id - UID config in database
 */
export async function getConfigById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/config/${id}`
  })

  return result.data
}
